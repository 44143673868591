/** @format */

.inventory-container {
	display: flex;
	flex-direction: column;
	background-color: white;
	overflow-x: hidden;
	justify-content: flex-start;
	gap: 10vh;
	overflow-x: clip;
	height: 300vh;

	video {
		position: absolute;
		height: 300vh;
		mix-blend-mode: exclusion;

		opacity: 0.1;
		z-index: 0;
		max-width: 99vw;
		object-fit: cover;
	}
	.thumbnails-container {
		display: flex;
		position: sticky;
		top: 20vh;
		width: fit-content; /* Fit content width */
		overflow: hidden;
		margin-left: 150px;

		/* Triplicate content */
		&::before,
		&::after {
			content: "";
			display: block;
			width: 100%; /* Match the width of the original content */
		}

		&::before {
			order: -1; /* Place one copy before the original content */
		}

		@media (max-width: 940px) {
			margin-left: 50px;
		}

		@media (max-width: 768px) {
			margin-left: 10px;
			margin-right: 10px;
		}

		.inventory-inner-container {
			display: flex;
			width: 100vw;
			min-width: 50vw;
			height: 100vh;
			flex-direction: column;
			gap: 10vh;
			object-fit: contain;

			img {
				position: absolute;
				z-index: -2;
				top: 0;
				object-fit: contain;
				height: 100%;
				height: 50vh;
			}

			@media (max-width: 768px) {
				min-width: 600px;
			}

			.inventory-title-wrapper {
				z-index: 4;
				padding: 24px;
				background-color: #12121292;
				display: flex;
				width: 20%;
				min-width: 300px;
				flex-direction: column;
				text-align: left;
				gap: 24px;

				p {
					color: white;
				}

				img {
					width: auto;
					height: 10vw;
				}
			}

			.inventory-text-container {
				display: flex;
				flex-direction: column;
				gap: 24px;
				text-align: left;
				max-width: 500px;
			}
		}

		.intro-inner-container {
			display: flex;
			width: 100vw;
			flex-direction: column;
			gap: 10vh;

			.intro-title-wrapper {
				display: flex;

				img {
					width: auto;
					height: 10vw;
				}
			}

			.intro-text-container {
				display: flex;
				flex-direction: column;
				gap: 24px;
				text-align: left;
				max-width: 500px;
			}
		}
	}
}

.left-btn-box {
	color: rgb(255, 255, 255) !important;
	width: fit-content !important;
	padding: 12px !important;
	border: rgb(255, 255, 255) 1px solid !important;
	border-radius: 10px !important;
	background-color: rgb(0, 0, 0) !important;
	&:hover {
		cursor: pointer;
	}
}

.sold-tag {
	color: rgb(255, 255, 255) !important;
	width: fit-content !important;
	padding: 12px !important;
	border: rgb(255, 255, 255) 1px solid !important;
	border-radius: 10px !important;
	background-color: #bd2209 !important;
	&:hover {
		cursor: pointer;
	}
}
