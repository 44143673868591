.Bitoutne-Embark {
  background: #fff;
  //   width: 1440px;
  //   height: 560px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  width: auto;
  height: 50vh;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 50px;

  h1 {
    color: #121212;
  }
  @media (min-width: 1920px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  flex-direction: row;

  @media (max-width: 1233px) {
    flex-direction: column-reverse;
    gap: 50px;
  }

  @media (max-width: 390px) {
    flex-direction: column-reverse;
    padding: 16px;
  }
}
.Embark-conatiner-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;

  @media (max-width: 1233px) {
    width: 100%;
  }
  .join-left-button {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    .left-btn-box {
      display: flex;
      padding: 16px 20px;
      align-items: center;
      gap: 8px;

      display: flex;
      padding: 16px 18x;
      align-items: center;
      gap: 8px;
      border-radius: 16px;
      border: 1px solid var(--Blue, #0e2e51);
      background: #fff;

      color: var(--Blue, #0e2e51);
      text-align: center;

      font-size: 16px;
      font-style: normal;
      font-weight: 700;

      letter-spacing: 1px;
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.embark-conatiner-left-items {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  .waiting-text {
    color: #0e2e51;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;
  }

  .embark-text {
    width: 90%;
    color: var(--Blue, #0e2e51);

    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 52.8px */
    letter-spacing: -1.32px;
    .gentiq {
      background: linear-gradient(180deg, #0e2e51 0%, #a8d7fa 121.67%);
      background-clip: text;
      margin-left: 10px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.Embark-conatiner-right {
  border-radius: 20px;
  display: flex;
  // width: 716px;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 393px;
  overflow: hidden;

  @media (max-width: 1233px) {
    width: 100%;
  }
  img {
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
  }
}
