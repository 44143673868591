.digitalTwin-container {
  position: relative;
  align-items: center;
  height: 900vh;
  background: #000000;
  flex-direction: column;
  width: 100%;

  // div {
  //   position: relative;
  //   height: 500px;
  // }
  .digitalTwin-innerContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    justify-content: center;

    overflow: visible;
    height: 100vh;
    padding-left: 60px;
    overflow: hidden;
    @media (max-width: 1440px) {
      padding-left: 60px;
      overflow: hidden;
    }
    @media (max-width: 768px) {
      padding: 16px;
    }
    .twin-videoWrapper {
      video {
        video {
          height: 100vh;
        }
      }

      @media (max-width: 1440px) {
        width: auto;
        height: 150%;
      }

      position: absolute;
      left: 0%;
      z-index: -1;
    }
  }

  .twin-innerContainerb {
    display: flex;
    position: relative;
    flex-direction: row;
    position: sticky;
    top: 70vh;
    justify-content: space-between;
    width: auto;

    padding-left: 60px;
    align-items: center;

    @media (max-width: 1200px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    @media (max-width: 768px) {
      padding-left: 16px;
    }
    video {
      width: auto;
      height: 60vh;
      border-radius: 20px;
      margin: 60px;
      margin-right: 60px;

      // @media (min-width: 768px) {
      //   width: 90vw;
      //   margin: 16px;
      //   height: auto;
      // }
      @media (max-width: 1049px) {
        margin: 0px;
        padding: 0px;
        height: auto;
        height: auto;
        width: 92%;
      }
      @media (max-width: 780px) {
        margin: 0px;
        padding: 0px;
        height: auto;
        height: auto;
        width: 92%;
      }
    }
  }

  .twin-img-wrapper {
    position: relative;
    display: flex;
    width: auto;
    margin: 0px;
    justify-content: center;

    .twin-overlayMode {
      position: absolute;
    }
    img {
      height: 90vh;
      @media (max-width: 768px) {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
