body {
  padding: 0px;
  margin: 0;
  background-color: #121212;

  font-family: "Hanken Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  margin: 0;
  font-family: "Hanken Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0 !important;
}
