.introJet-container {
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  justify-content: flex-start;
  gap: 10vh;
  overflow-x: clip;
  height: 300vh;

  video {
    position: absolute;
    height: 300vh;
    mix-blend-mode: multiply;
    z-index: 2;
    max-width: 99vw;
    object-fit: cover;
  }

  .thumbnails-container {
    display: flex;
    position: sticky;
    top: 20vh;
    width: 300vw;
    overflow: hidden;

    margin-left: 150px;
    @media (max-width: 940px) {
      margin-left: 50px;
    }

    @media (max-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    .intro-inner-container {
      display: flex;
      width: 100vw;
      flex-direction: column;
      gap: 10vh;

      .intro-title-wrapper {
        display: flex;

        img {
          width: auto;
          height: 10vw;

          // @media (max-width: 768px) {
          //   width: auto;
          //   height: 10vw;
          // }

          // @media (max-width: 1300px) {
          //   width: auto;
          //   height: auto;
          // }
        }
      }
      .intro-text-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-align: left;
        max-width: 500px;
      }
    }
  }
}
