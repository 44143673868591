.Lab-container {
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-image: url(../../Assets/whats-new.png);
  background-blend-mode: darken;
  background-repeat: no-repeat;

  background-size: cover;

  .text-clinic-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    background-color: rgba(255, 255, 255, 0.398);
    backdrop-filter: blur(6.5px);
    padding: 16px;
    @media (max-width: 786px) {
      padding-left: 16px;
      padding-right: 16px;
    }
    h1 {
      text-align: center;
      color: #121212;
      @media (max-width: 1440px) {
        font-size: 60px;
      }

      @media (max-width: 768px) {
        font-size: 30px;
      }
    }
    p {
      color: #121212;
      @media (max-width: 1440px) {
        font-size: 15px;
      }
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    span {
      color: #121212;
    }
  }

  .lab-img-wrapper {
    position: absolute;
    z-index: -1;
    position: relative;
    width: 100vw;

    img {
      width: 100%;
    }
  }
}
