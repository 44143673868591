.advanceImaging-container {
  position: relative;
  align-items: center;
  height: 400vh;
  background: #121212;
  flex-direction: column;
  width: 100%;

  // div {
  //   position: relative;
  //   height: 500px;
  // }
  .advanceImaging-innerContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    justify-content: center;

    height: 100vh;
    width: 100vw;
    overflow: hidden;

    padding-left: 150px;
    @media (max-width: 940px) {
      padding-left: 50px;
    }

    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    video {
      height: 100vh;

      position: absolute;
      left: -0%;
      z-index: -2;
    }

    img {
      width: 80%;
    }
  }

  .advanceImaging-innerContainerb {
    display: flex;
    position: relative;
    flex-direction: column;
    position: sticky;
    top: 60vh;
    justify-content: center;

    padding-left: 60px;
    overflow: visible;

    @media (max-width: 978px) {
      padding-left: 16px;
    }
    @media (max-width: 768px) {
      padding-left: 16px;
      top: 80vh;
    }

    .advanceImaging-img-wrapper {
      position: relative;
      display: flex;
      width: auto;
      margin: 0px;
      justify-content: center;

      .image-overlayMode {
        position: absolute;
      }
      img {
        width: 90%;
        max-width: 1050px;
      }
    }
  }
}

.advanceImaging-text-container {
  display: flex;
  max-width: 800px;
  text-align: left;
  flex-direction: column;
  gap: 24px;
  div {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  @media (max-width: 768px) {
    width: 300px !important;
  }
}
