/* PlaneUpload.css */
.container {
	width: 80%;
	margin: 0 auto;
	padding: 20px;
	font-family: Arial, sans-serif;
	background-color: #333; /* Set background color for contrast */
	border-radius: 8px;
}

h2 {
	text-align: center;
	color: #fff; /* White text */
}

input[type="text"],
textarea {
	width: 90%;
	padding: 10px;
	margin: 10px 10px;
	border: 1px solid #555; /* Darker border */
	border-radius: 5px;
	font-size: 16px;
	background-color: #444; /* Dark background for input fields */
	color: #fff; /* White text */
}

textarea {
	resize: vertical;
	height: 100px;
}

label {
	display: flex;
	align-items: center;
	margin: 10px 0;
	color: #fff; /* White text */
}

input[type="checkbox"] {
	margin-left: 10px;
}

input[type="file"] {
	margin: 10px 0;
	color: #fff; /* White text */
}

button {
	background-color: #4caf50;
	color: #fff; /* White text */
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	margin-top: 20px;
	width: 100%;
}
button:hover {
	background-color: rgba(204, 204, 204, 0.385) f50;
}

button:disabled {
	background-color: #ccc;
}

ul {
	list-style: none;
	padding: 0;
}

li {
	background-color: #444; /* Dark background for list items */
	padding: 20px;
	margin: 10px 0;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	color: #fff; /* White text */
}

li h3 {
	margin: 0;
	font-size: 20px;
	color: #fff; /* White text */
}

li p {
	margin: 10px 0;
	color: #ccc; /* Light gray text */
}

li img {
	max-width: 100%;
	height: auto;
	border-radius: 5px;
}

li a {
	color: #4caf50;
	text-decoration: none;
	font-weight: bold;
}

li a:hover {
	text-decoration: underline;
}
