.Navbar-container {
  display: flex;
  padding: 10px 30px 10px 30px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.869);
  backdrop-filter: blur(6.5px);
  transition: 0.2s;
  z-index: 3;
}

.Navbar-action-container {
  display: flex;
  align-items: center;
  gap: 32px;
  a {
    color: #0e2e51;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    letter-spacing: 0.2px;
    text-decoration: none;

    &:hover {
      color: #0e2e5156;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
}
