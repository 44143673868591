.VrGlasess-container {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  height: 700vh;
  background: #000000;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
  video {
    height: 100vh;
    width: auto;
    position: absolute;
    left: -0%;
    z-index: -2;
  }

  // div {
  //   position: relative;
  //   height: 500px;
  .VrInner-container {
    position: sticky;
    display: flex;
    width: 100%;
    align-items: center;
    top: 0;
    justify-content: space-evenly;
    height: 100vh;
    padding-left: 150px;
    overflow: hidden;

    flex-direction: row;
    @media (max-width: 940px) {
      padding-left: 50px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      padding-left: 0px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    .Vr-text-container {
      display: flex;
      text-align: left;
      max-width: 400px;
      flex-direction: column;
      position: sticky;
      height: fit-content;
      top: 0;
      gap: 24px;
      div {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      @media (max-width: 600px) {
        width: 90%;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .Vr-text-containerb {
      display: flex;
      text-align: left;
      max-width: 600px;
      flex-direction: column;
      position: sticky;
      height: fit-content;
      top: 0;
      gap: 24px;
      div {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      @media (max-width: 600px) {
        width: 90%;
      }
    }
    .Vr-image-container {
      display: flex;
      flex-direction: column;

      overflow: hidden;
      padding-left: 50px;

      @media (max-width: 768px) {
        padding-left: 16px;
      }
    }
  }
}
