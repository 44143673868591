.Genetic-footer {
  display: flex;
  width: auto;
  flex-direction: row;
  padding: 50px 50px 50px 50px;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
  background: #fff;
  text-align: left;
  gap: auto;
  @media (max-width: 890px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
}

.hover {
  &:hover {
    cursor: pointer !important;
    color: #2e2e2e !important;
  }
}
.footer-right-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  gap: 80px;
  @media (max-width: 550px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }

  .footer-right-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: fit-content;

    .addresss-text {
      color: var(--Black, #2e2e2e);

      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 141.176% */
      letter-spacing: 0.34px;
    }
  }
}
.footer-left-logo {
  display: flex;
  align-items: flex-start;
  height: 100%;
  img {
    width: 182px;
  }
}
.text-box-footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: fit-content;
  max-width: 200px;

  align-self: stretch;
  color: var(--Gray, #6d6d6d);

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
  letter-spacing: 0.34px;
}

.text-box-footer-right2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  div {
    color: var(--Gray, #6d6d6d);

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 141.176% */
    letter-spacing: 0.34px;
  }
}

.footer-right-item3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.address-text {
  justify-self: center;
  color: var(--Black, #2e2e2e);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 141.176% */
  letter-spacing: 0.34px;
}
