.App {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-direction: column;
  padding: 0px;
}
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.Hero-outter-container {
}

.oppener-outter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.animatedCarousel-outter-container {
  display: flex;
  height: fit-content;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.oppener-outter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-outter-container {
  display: flex;

  flex-direction: column;
  position: relative;
  width: 100%;
}

.lab-outter-container {
  display: flex;
  width: 100%;
}

.intro-outter-container {
  display: flex;
  margin-top: 20vh;
}

.inventory-outter-container {
  display: flex;
  margin-top: 0vh;
}

.services-outter-container {
  display: flex;
}
a {
  text-decoration: none;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

h1 {
  font-size: 56px;
  font-weight: 200;
  line-height: 100%;
  margin: 0px;
  color: white;
}
h2 {
  font-size: 24;
  margin: 0px;
  font-weight: 500;
  color: white;
}
p {
  font-size: 17;
  margin: 0px;
  font-weight: 300;
  color: white;
}
span {
  font-weight: 600;
  color: #d0ad79;
}
