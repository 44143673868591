.heroJet-container {
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  height: 100vh;
  justify-content: center;
  gap: 20vh;

  @media (max-width: 940px) {
    margin-left: 50px;
  }

  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .hero-title {
    font-size: 120px;
    max-width: 652px;
    text-align: left;
    line-height: 110px;

    @media (max-width: 768px) {
      font-size: 80px;
      margin-left: 10px;
      margin-right: 10px;
      line-height: 65px;
    }

    @media (max-width: 498px) {
      font-size: 45px;
      margin-left: 10px;
      margin-right: 10px;
      line-height: 45px;
    }
  }

  .hero-scroll-down {
  }
}
